/*
    Use the DejaVu Sans font for displaying and embedding in the PDF file.
    The standard PDF fonts do not support Unicode characters.
*/
/* div {
    font-family: "Noto Sans", "Seoge UI", serif;
} */

/*
    The example loads the DejaVu Sans from the Kendo UI CDN.
    Other fonts have to be hosted from your application.
    The official site of the Deja Vu Fonts project is
    https://dejavu-fonts.github.io/.
*/

@font-face {
    font-family: "Noto Sans";
    src: url("../../../public/assets/fonts/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans";
    font-weight: bold;
    src: url("../../../public/assets/fonts/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans";
    font-style: italic;
    src: url("../../../public/assets/fonts/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans";
    font-weight: bold;
    font-style: italic;
    src: url("../../../public/assets/fonts/NotoSans-Bold.ttf") format("truetype");
}

#color {
    font-family: "Noto Sans", serif;
}

#pattern {
    font-family: "Noto Sans", serif;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}


.a4-landscape {
  width: 29.7cm;
  /* max-height: 21cm; */
  max-height: max-content;
  font-family: "Cambria";
}

.a4-portrait {
  width: 21cm;
  /* max-height: 29.7cm; */
  max-height: max-content;
  font-family: "Cambria";
}

.a5-landscape {
  width: 29.7cm;
  /* max-height: 21cm; */
  max-height: max-content;
  font-family: "Cambria";
}

.a5-portrait {
  width: 210mm;
  max-height: max-content;
  font-family: "Cambria";
}

.a4-landscape::-webkit-scrollbar {
  display: none;
}

.a4-portrait::-webkit-scrollbar {
  display: none;
}

.a5-landscape::-webkit-scrollbar {
  display: none;
}

.a5-portrait::-webkit-scrollbar {
  display: none;
}