@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progress-indicator.available {
  opacity: 0;
}

.progress-indicator {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 9999;
}

.progress-indicator-head {
  background-color: #c6dafc;
  height: 4px;
  overflow: hidden;
  position: relative;
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140px;
  height: 140px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, .5);
  background-image: url("../public/assets/images/logo.png");
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 137px 137px;
  padding: 1px;
}

.ring:before {
  content: '';
  position: absolute;
  left: -0.5px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2CA4BE;
  border-right: 3px solid #2CA4BE;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.progress-indicator .first-indicator,
.progress-indicator .second-indicator {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
}

.progress-indicator .first-indicator {
  -webkit-animation: first-indicator 2s linear infinite;
  -moz-animation: first-indicator 2s linear infinite;
  animation: first-indicator 2s linear infinite;
}

.progress-indicator .second-indicator {
  -webkit-animation: second-indicator 2s linear infinite;
  -moz-animation: second-indicator 2s linear infinite;
  animation: second-indicator 2s linear infinite;
}

.progress-indicator .insp-logo {
  animation: App-logo-spin infinite 20s linear;
  border-radius: 50%;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
}


@keyframes stretch {
  0% {
    transform: scale(0.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes first-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  25% {
    transform: translate(0) scaleX(0.5);
  }

  50% {
    transform: translate(25%) scaleX(0.75);
  }

  75% {
    transform: translate(100%) scaleX(0);
  }

  100% {
    transform: translate(100%) scaleX(0);
  }
}

@keyframes second-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  60% {
    transform: translate(0) scaleX(0);
  }

  80% {
    transform: translate(0) scaleX(0.6);
  }

  100% {
    transform: translate(100%) scaleX(0.1);
  }
}


/* custom-select */
.customSelect__control {
  box-shadow: none !important;
  padding: 1.5px !important;
  border-radius: 0.5rem !important;
}

.select__control {
  box-shadow: none !important;
  border-radius: 0.5rem !important;
}

aside::-webkit-scrollbar {
  display: none;
}

.react-responsive-modal-modal {
  max-width: max-content !important;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.nav-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.nav-toggle:hover {
  transform: rotate(90deg);
}

.nav-links {
  display: flex;
  flex-direction: column;
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.nav-links li {
  margin-top: 0.5rem;
}

.nav-links a {
  text-decoration: none;
}

.show-nav {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

  .ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
  }

.bhHnmT {
  overflow-x: clip !important;
  overflow-y: visible !important;
}

.dSZDRP {
  position: unset !important;
}

.dApqnJ {
  position: unset !important;
}

.kVrXuC {
  position: unset !important;
}

.css-t3ipsp-control {
  border-color: #2CA4BE;
  z-index: 0 !important;
}
.customSelect__control, .select__control {
  border-color: rgb(173 181 189 / var(--tw-border-opacity));
}

#designNo {
  border-color: rgb(173 181 189 / var(--tw-border-opacity));
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #D9D9D9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2CA4BE;
  border-radius: 10px;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #66c5d9;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

#row--1{
  background:rgb(230, 244, 244);
  position:sticky;
  bottom:0;
}

.btn {
  text-decoration: none;
  font-size: inherit;
  border: 0;
  line-height: 1;
}
.btn-1 {
  --clr: white;
  --outline: 2px solid var(--clr);
  outline-offset: -2px;
  transition: outline-offset 200ms ease;
}

.btn-1:hover {
  outline: var(--outline);
  outline-offset: 2px;
}
.setting-btn-1 {
  --clr: #2CA4BE;
  --outline: 2px solid var(--clr);
  color: var(--clr);
  outline-offset: -2px;
  transition: outline-offset 200ms ease;
}
.setting-btn-1:hover {
  outline: var(--outline);
  outline-offset: 0.5px;
}

@media print {
  @page {
    margin: 0.6cm; /* Adjust margin values as needed */
  }
}
